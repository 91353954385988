import request from '../utils/request'
import qs from 'qs'
import {encrypt} from "../utils/rsautil" //RSA加密类
import md5 from 'js-md5';
/**
 * 获取菜单
 * @param query
 * @returns {*}
 */
export function getModule(query) {
    return request({
        url: 'index/modules.do',
        method: 'post',
        data: qs.stringify(query)
    })
}

/**
 * 获取用户信息
 * @returns {*}
 */
export function getUserInfo(){
    return request({
        url: 'index/info.do',
        method: 'post'
    })
}
/**
 * 实名认证
 * @returns {*}
 */
export function toPersonalAuth(name,cardType,cardNumber,phone,optType){
    return request({
        url: 'index/toPersonalAuth.do',
        method: 'post',
        data: qs.stringify({name:name,cardType:cardType,cardNumber:cardNumber,phone:phone,optType:optType,callBackCode:6})
    })
}

/**
 * 修改手机号码
 * @param oldPhone 原号码
 * @param newPhone 新号码
 * @param code 验证码
 */
export function modifyPhone(oldPhone,newPhone,code){
    return request({
        url: 'index/modifyPhone.do',
        method: 'post',
        data: qs.stringify({oldPhone: encrypt(oldPhone),newPhone:encrypt(newPhone),code: encrypt(code)})
    })
}

/**
 * 修改密码
 * @param oldPass
 * @param newPass
 * @param newPass1
 */
export function modifyPass(oldPass,newPass,newPass1){
    return request({
        url: 'index/modifyPass.do',
        method: 'post',
        data: qs.stringify({oldPass: encrypt(md5(oldPass)),newPass:encrypt(md5(newPass)),newPass1: encrypt(md5(newPass1))})
    })
}