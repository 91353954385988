<template>
  <div>
    <div class="container">
      <div class="header-container">
        <header>
          <van-nav-bar
              :title="title"
              left-text="返回"
              right-text=""
              left-arrow
              @click-left="onBack"
          />
        </header>
      </div>
      <van-form @submit="onSubmit">
        <div class="section-box" style="padding: 5px">
            <van-cell title="姓名：" :value="userInfo.account.name | deseHandler(1,1,'*')" />
            <van-cell title="证件类型：" :value="userInfo.account.cardType" />
            <van-cell title="证件号码：" :value="userInfo.account.cardNumber | deseHandler(5,4,'*')" />
            <van-cell title="手机号码：" :value="userInfo.account.phone | deseHandler(3,4,'*')" />
        </div>
        <van-overlay :show="show" @click="show = false">
          <div class="auth-wrapper" @click.stop>
            <div class="auth-block">
              <div class="auth-block-header">信息确认</div>
              <van-field
                  v-model="userInfo.account.name"
                  name="name"
                  label="姓名"
                  border
                  colon
                  placeholder="请填写姓名"
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  v-model="userInfo.account.phone"
                  name="phone"
                  label="手机号码"
                  type="digit"
                  border
                  colon
                  placeholder="请填写手机号码"
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  readonly
                  clickable
                  name="cardType"
                  :value="userInfo.account.cardType"
                  label="证件类型"
                  border
                  colon
                  required
                  placeholder="点击选择证件类型"
                  @click="showPicker = true"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="zjTypes"
                    @confirm="onCardTypeConfirm"
                    @cancel="showPicker = false"
                />
              </van-popup>
              <van-field
                  v-model="userInfo.account.cardNumber"
                  name="cardNumber"
                  label="证件号码"
                  placeholder="请填写证件号码"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <div class="auth-block-footer">
                <van-row>
                  <van-col span="12" style="border-right:1px grey solid;">
                    <van-button type="default" block native-type="button" @click="show = false">取消</van-button>
                  </van-col>
                  <van-col span="12"><van-button type="info" block>确认</van-button></van-col>
                </van-row>
              </div>
            </div>
          </div>
        </van-overlay>
        <div style="margin: 16px;" v-if="!isAuth">
          <van-button type="info" round block native-type="button" @click="dataConfirm">去实名认证</van-button>
        </div>
        <div style="margin: 16px;" v-if="isAuth">
          <van-button disabled  type="info" round block>已经实名认证</van-button>
        </div>
      </van-form>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "@/components/logding";
import {getKey, setKey} from "@/utils/cookie";
import {toPersonalAuth} from "@/api";

export default {
  name: "index",
  components: {Loading},
  data(){
    return{
      isShow : false,
      title:'个人信息',
      zjTypes:['身份证','军官证','护照','驾驶证','回乡证'],
      showPicker: false,
      userInfo:JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}'),
      isAuth: parseInt(getKey(process.env.VUE_APP_USER_IS_AUTH) || '0'),
      lodingTxt: '加载中...',
      optType:0,
      show: false
    }
  },
  filters:{
    deseHandler: function (str, frontLen, endLen, wildcard) {
      if (!str) {
        return "--";
      } else if (str.length < 9) {
        return str;
      } else {
        var len = str.length - frontLen - endLen;
        var xing = "";
        for (var i = 0; i < len; i++) {
          xing += wildcard;
        }
        return (
            str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
        );
      }
    }
  },
  created() {
    //重新获取登录状态
    const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
    this.isLogin = isLogin
    if (isLogin){
      const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')
      if (userinfo && userinfo.account){
        console.log(userinfo)
        this.userInfo = userinfo
      }
    }
  },
  methods:{
    /**
     * 提交
     */
    onSubmit(){
      const that = this
      that.lodingTxt = '正在保存...'
      that.isShow = true
      toPersonalAuth(that.userInfo.account.name,that.userInfo.account.cardType,that.userInfo.account.cardNumber,that.userInfo.account.phone,1).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.show = false
          window.location.href = response.data.url
        }else{
          if (response.code == 400) {
            that.show = false
            that.isAuth = 1
            //更新缓存
            setKey(process.env.VUE_APP_USER_IS_AUTH, 1)
            that.userInfo.isAuth = 1
            setKey(process.env.VUE_APP_USER_INFO,JSON.stringify(that.userInfo))
          }
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onBack : function (){
      this.$router.go(-1);
    },/**
     * 证件类型选中
     * @param e
     */
    onCardTypeConfirm(e){
      let oldType = this.userInfo.account.cardType
      if (oldType == '居民身份证') oldType='身份证'
      this.userInfo.account.cardType = e
      this.showPicker = false
      if (oldType != e){
        this.userInfo.account.cardNumber = ''
      }
    },
    dataConfirm(){
      this.show = true
    }
  }
}
</script>

<style scoped>
.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.auth-block {
  width: 90vw;
  height: 290px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.auth-block .auth-block-header,auth-block-footer{
  height: 44px;
  line-height: 44px;
}
.auth-block .auth-block-header{text-align: center;border-bottom: #efefef solid 1px;}
.van-cell--clickable{
  padding:0.26667rem 0.42667rem!important;
}
</style>
